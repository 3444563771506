<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="编辑用户"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 4 }">
      <a-form-item label="账号" v-bind="validateInfos.account">
        <a-input
          v-model:value="userVo.account"
          disabled
          :maxlength="16"
          placeholder="用户登录账号"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="姓名" v-bind="validateInfos.name">
        <a-input
          v-model:value="userVo.name"
          :maxlength="16"
          placeholder="用户姓名"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="所属角色" v-bind="validateInfos.roleId">
        <a-select
          v-model:value="userVo.roleId"
          placeholder="用户角色"
          allow-clear
        >
          <a-select-option
            v-for="item in roles"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, reactive, toRefs } from "vue";
import useValidateRules from "@/hooks/useValidateRules";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { allRoles } from "@/api/sys/sysRoleApi";
import { edit, detail } from "@/api/sys/sysUserApi";
import axios from "axios";

const useForm = Form.useForm;
export default {
  name: "Edit",
  props: ["modalRef", "id"],
  emits: ["close"],
  setup(props, { emit }) {
    const disabled = ref(false);
    const userVo = reactive({
      account: "",
      name: "",
      roleId: "",
    });
    const roles = reactive([]);
    const { sysUserRules } = useValidateRules();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const { validateInfos, validate } = useForm(userVo, sysUserRules);
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const doSubmit = () => {
      validate().then(async () => {
        let action = await showConfirm("修改用户信息?");
        if (action) {
          disabled.value = true;
          await showLoading();
          try {
            let obj = {
              name: userVo.name,
              roleId: userVo.roleId,
            };
            await edit(props.id, obj);
            await closeLoading();
            await showTipMessage("修改成功");
            refresh = true;
            visible.value = false;
          } catch (e) {
            await closeLoading();
            await showErr(e);
            disabled.value = false;
          }
        }
      });
    };
    onMounted(async () => {
      try {
        let [r1, r2] = await axios.all([allRoles(), detail(props.id)]);
        roles.push(...r1.data);
        userVo.name = r2.data.name;
        userVo.roleId = r2.data.roleId;
        userVo.account = r2.data.account;
      } catch (e) {
        await showErr(e);
      }
    });
    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      roles,
      userVo,
      validateInfos,
      doSubmit,
    };
  },
};
</script>

<style scoped></style>
