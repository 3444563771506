<template>
  <div ref="modalContainer"></div>
  <a-card class="content-wrapper">
    <template #title>
      <CardHeader></CardHeader>
    </template>
    <div class="body-content">
      <a-form layout="inline">
        <a-form-item label="用户名">
          <a-input
            v-model:value="params.name"
            placeholder="输入账号或姓名查询"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="doQuery">
            <template #icon>
              <SearchOutlined />
            </template>
            查询
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button class="btn-success" @click="showAddModal">
            <template #icon>
              <PlusCircleOutlined />
            </template>
            添加
          </a-button>
        </a-form-item>
      </a-form>
      <div style="padding: 4px 0">
        <a-table
          row-key="id"
          table-layout="fixed"
          :data-source="dataList.list"
          :columns="columns"
          :loading="loading"
          :pagination="false"
          :scroll="{ y: 500 }"
          bordered
        >
          <template #operation="{ record }">
            <div v-if="record.id !== '1'">
              <TipButton
                perm="sys:user:edit"
                btn-class="btn-success"
                title="编辑"
                @btnClick="showEditModal(record.id)"
              >
                <template #default>
                  <EditOutlined />
                </template>
              </TipButton>
              <TipButton
                perm="sys:user:del"
                btn-class="btn-danger"
                title="删除"
                @btnClick="delItem(record.id)"
              >
                <template #default>
                  <DeleteOutlined />
                </template>
              </TipButton>
            </div>
          </template>
        </a-table>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:page-size="pageSize"
        :total="total"
        :show-total="total => `${total}条数据`"
        @change="currentChange"
      >
      </a-pagination>
    </div>
  </a-card>
  <add
    v-if="addShow"
    :modal-ref="$refs.modalContainer"
    @close="hideAddModal"
  ></add>
  <edit
    v-if="editShow"
    :id="currentId"
    :modal-ref="$refs.modalContainer"
    @close="hideEditModal"
  ></edit>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import useSysUser from "@/hooks/system/useSysUser";
import usePagination from "@/hooks/usePagination";
import useAddExit from "@/hooks/useAddExit";
import { onMounted } from "vue";
import Add from "@/views/System/User/components/Add";
import Edit from "@/views/System/User/components/Edit";
import useMessage from "@/hooks/useMessage";
import { deleteUser } from "@/api/sys/sysUserApi";
import TipButton from "@/components/TipButton";

export default {
  name: "index",
  components: {
    Edit,
    Add,
    CardHeader,
    TipButton,
  },
  setup() {
    const { params, dataList, total, loading, columns, loadData } =
      useSysUser();

    const { page, pageSize, currentChange, doQuery, doRefresh } =
      usePagination(loadData);

    const {
      currentId,
      addShow,
      editShow,
      showAddModal,
      showEditModal,
      hideAddModal,
      hideEditModal,
    } = useAddExit(doQuery);

    const { showConfirm, showLoading, closeLoading, showTipMessage, showErr } =
      useMessage();

    onMounted(() => {
      loadData(page.value, pageSize.value);
    });

    const delItem = async id => {
      let action = await showConfirm(
        "删除用户将造成已登录的用户无法继续使用，是否继续?",
      );
      if (action) {
        await showLoading("正在删除，请稍后...");
        try {
          await deleteUser(id);
          await closeLoading();
          await showTipMessage("删除成功", "success");
          doQuery();
        } catch (e) {
          await closeLoading();
          await showErr(e);
        }
      }
    };

    return {
      params,
      page,
      pageSize,
      total,
      loading,
      currentChange,
      dataList,
      doQuery,
      doRefresh,
      columns,

      currentId,
      addShow,
      editShow,
      showEditModal,
      showAddModal,
      hideAddModal,
      hideEditModal,
      delItem,
    };
  },
};
</script>

<style scoped></style>
